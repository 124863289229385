table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}



/* The scrollable part */

.scrollable {
  height: 400px;
  overflow-y: scroll;
  border-bottom: 1px solid #ddd;
}

th {
  position: sticky;
  background-color: white;
  z-index: 2;
  top: 0;
}